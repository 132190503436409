// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-fitbit-js": () => import("./../src/pages/myFitbit.js" /* webpackChunkName: "component---src-pages-my-fitbit-js" */),
  "component---src-pages-my-garden-js": () => import("./../src/pages/myGarden.js" /* webpackChunkName: "component---src-pages-my-garden-js" */),
  "component---src-pages-my-spotify-js": () => import("./../src/pages/mySpotify.js" /* webpackChunkName: "component---src-pages-my-spotify-js" */),
  "component---src-pages-my-work-tsx": () => import("./../src/pages/myWork.tsx" /* webpackChunkName: "component---src-pages-my-work-tsx" */)
}

